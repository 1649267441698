.photo:nth-child(2) {
  grid-column: 2 / -1;
  grid-row: span 2;
}
@media (max-width: 40rem) {
  .photo:nth-child(2n) {
    grid-column: initial;
    grid-row: initial;
  }
}

.photo {
  display: grid;
  border-radius: 0.2rem;
  overflow: hidden;
  cursor: pointer;
}
.photo > div {
  grid-area: 1/1;
}
.views {
  grid-area: 1/1;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: none;
  gap: 0.5rem;
}
.views::before {
  content: "";
  height: 10px;
  width: 16px;
  background: url("../../Assets/visualizacao.svg") no-repeat center center;
}
.photo:hover .views {
  display: flex;
}
