.form {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: stretch;
  margin: 1rem;
}
.single.form {
  margin: 1rem 0;
}
.textarea {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-family: var(--type-first);
  resize: none;
  border: 1px solid #eee;
  padding: 0.5rem;
  border-radius: 0.2rem;
  background: #eee;
  transition: 0.2s;
}
.textarea:focus,
.textarea:hover {
  outline: none;
  border-color: #fb1;
  background: #fff;
  box-shadow: 0 0 0 3px #fea;
}
.btn {
  border: none;
  cursor: pointer;
  color: #333;
  background: transparent;
  font-size: 1rem;
  padding: 0 1rem;
  overflow: hidden;
}
.btn:focus,
.btn:hover {
  outline: none;
}
.btn:focus svg path,
.btn:hover svg path {
  fill: #fea;
  stroke: #fb1;
}
.btn:focus svg g,
.btn:hover svg g {
  animation: latir 0.6s infinite;
}

@keyframes latir {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
