.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background: #fff;
  top: 0;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.logo {
  padding: 0.5rem 0;
}
.login {
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.login::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 17px;
  background: url("../Assets/usuario.svg") no-repeat center center;
  position: relative;
  top: -1px;
}
