.wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  top: 0;
  left: 0;
  z-index: 10000;
}
.loading {
  margin: auto;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
}
